require('./bootstrap');
import MobileMenu from './mobile-menu';
import Slider from './slider';
import { Fancybox } from "@fancyapps/ui";
import Categori from './categori';
import Footer from './footer';
import 'flowbite';

new MobileMenu();
new Slider();
new Categori();
new Footer();
