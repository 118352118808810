import Splide from "@splidejs/splide";

export default class Slider {
  constructor() {
    document.addEventListener("DOMContentLoaded", function () {
      if (document.querySelector(".splide-teams")) {
        const splideTeams = new Splide(".splide-teams", {
          type: "loop",
          speed: 500,
          autoplay: false,
          interval: 5000,
          gap: "2em",
        });

        splideTeams.mount();
      }

      if (document.querySelector(".hero-slider")) {
        const heroSlider = new Splide(".hero-slider", {
          type: "loop",
          speed: 500,
          autoplay: true,
          interval: 5000,
          gap: "2em",
        });

        heroSlider.mount();
      }

      if (document.querySelector(".main-slider")) {
        const main = new Splide(".main-slider", {
          type: "fade",
          rewind: true,
          pagination: false,
          arrows: true,
        });

        const thumbnails = new Splide(".thumbnail-slider", {
          fixedWidth: 100,
          fixedHeight: 100,
          gap: 10,
          rewind: true,
          pagination: false,
          cover: false,
          isNavigation: true,
          arrows: false,
          breakpoints: {
            600: {
              fixedWidth: 60,
              fixedHeight: 60,
            },
          },
        });

        main.sync(thumbnails);
        main.mount();
        thumbnails.mount();
      }

      if (document.querySelector(".recommendation-slider")) {
        const recommendation = new Splide(".recommendation-slider", {
          perPage: 5,
          rewind: false,
          gap: 10,
          breakpoints: {
            600: {
              perPage: 2
            },
            1024: {
              perPage: 4
            },
          },
        });

        recommendation.mount();
      }
    });
  }
}
