export default class Footer {
  constructor() {
    this.init();
  }

  init = () => {
    this.backToTop();
    this.scrool();
    this.copyToClipboard();
  };

  backToTop = () => {
    document.querySelector(".js-back-to-top").addEventListener("click", () => {
      window.scrollTo(0, 0);
    });
  };

  scrool = () => {
    let lastKnownScrollPosition = 0;
    let ticking = false;

    function doSomething(scrollPos) {
      if (scrollPos > 200) {
        document.querySelector(".js-back-to-top").classList.add("-active");
      } else {
        document.querySelector(".js-back-to-top").classList.remove("-active");
      }
    }

    document.addEventListener("scroll", function (e) {
      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(function () {
          doSomething(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    });
  };

  copyToClipboard = () => {
    let copyButtons = document.querySelectorAll(".js-copy-button");

    copyButtons.forEach((copyButton) => {
      copyButton.addEventListener("click", () => {
        let copyText = copyButton.previousSibling.previousSibling;

        copyText.select();
        document.execCommand("copy");
      });
    });
  }
}
